// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kullanici-sozlesmesi-ve-gizlilik-politikasi-tsx": () => import("./../../../src/pages/kullanici-sozlesmesi-ve-gizlilik-politikasi.tsx" /* webpackChunkName: "component---src-pages-kullanici-sozlesmesi-ve-gizlilik-politikasi-tsx" */),
  "component---src-pages-legal-warning-en-tsx": () => import("./../../../src/pages/legal-warning.en.tsx" /* webpackChunkName: "component---src-pages-legal-warning-en-tsx" */),
  "component---src-pages-t-faq-en-tsx": () => import("./../../../src/pages/t/faq.en.tsx" /* webpackChunkName: "component---src-pages-t-faq-en-tsx" */),
  "component---src-pages-t-kullanici-sozlesmesi-ve-gizlilik-politikasi-tsx": () => import("./../../../src/pages/t/kullanici-sozlesmesi-ve-gizlilik-politikasi.tsx" /* webpackChunkName: "component---src-pages-t-kullanici-sozlesmesi-ve-gizlilik-politikasi-tsx" */),
  "component---src-pages-t-legal-warning-en-tsx": () => import("./../../../src/pages/t/legal-warning.en.tsx" /* webpackChunkName: "component---src-pages-t-legal-warning-en-tsx" */),
  "component---src-pages-t-sss-tsx": () => import("./../../../src/pages/t/sss.tsx" /* webpackChunkName: "component---src-pages-t-sss-tsx" */),
  "component---src-pages-t-user-agreement-and-privacy-policy-en-tsx": () => import("./../../../src/pages/t/user-agreement-and-privacy-policy.en.tsx" /* webpackChunkName: "component---src-pages-t-user-agreement-and-privacy-policy-en-tsx" */),
  "component---src-pages-t-yasal-uyari-tsx": () => import("./../../../src/pages/t/yasal-uyari.tsx" /* webpackChunkName: "component---src-pages-t-yasal-uyari-tsx" */),
  "component---src-pages-user-agreement-and-privacy-policy-en-tsx": () => import("./../../../src/pages/user-agreement-and-privacy-policy.en.tsx" /* webpackChunkName: "component---src-pages-user-agreement-and-privacy-policy-en-tsx" */),
  "component---src-pages-yasal-uyari-tsx": () => import("./../../../src/pages/yasal-uyari.tsx" /* webpackChunkName: "component---src-pages-yasal-uyari-tsx" */)
}

